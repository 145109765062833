<template>
  <div>
    <el-card>
      <div class="flex flex-align-center flex-wrap gap-20">
        <div class="flex flex-align-center gap-10">
          <label class="keyLabel">关键字: </label>
          <el-input v-model="searchKey" placeholder="订单编号" @keyup.enter.native="goodsFilter"
            style="width: 200px;" clearable></el-input>
        </div>
        <div class="flex flex-align-center gap-10">
          <label class="keyLabel">订单状态: </label>
          <el-select v-model="orderStage" style="width:200px;">
            <el-option v-for="(item,index) in orderStageList" :key="index+'-orderStage'" :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
        <div class="flex flex-align-center gap-10">
          <label class="keyLabel">时间筛选: </label>
          <el-select v-model="timeType" style="width: 180px;">
            <el-option v-for="(item,index) in timeTypeList" :key="index" :label="item.value" :value="item.id">
            </el-option>
          </el-select>
          <el-date-picker
            v-model="searchTime"
            type="daterange"
            range-separator="至"
            value-format="yyyy-MM-dd"
            format="yyyy-MM-dd"
            start-placeholder="开始日期"
            end-placeholder="结束日期">
          </el-date-picker>
        </div>
        <el-button type="primary" @click="goodsFilter">查询</el-button>
        <buttonPermissions :datas="'taokeDistributionOrderExport'">
          <el-button @click="exportFun">导出</el-button>
        </buttonPermissions>
      </div>
      <!-- 表格 -->

      <div class="table-container" style="margin-top:10px">
        <el-table border style="width: 100%;" v-loading="loading" ref="multipleTable" :data="groupData"
          :row-class-name="setClassName" :span-method="arraySpanMethod" :header-row-style="headerStyle">
          <el-table-column label="商品" width="220px">
            <template slot-scope="scope">
              <div class="header-box" v-if='scope.row.type===1'
                :style="{background:scope.row.type == 1?'rgba(64, 158, 255,.1)':'transparents'}">
                <div class="header-info">
                  <img src="http://xsoss2016.oss-cn-hangzhou.aliyuncs.com/melyshop/image/taokePinDuoDuo.png"
                   style="width:40px;height:16px;margin-right:5px" v-if="scope.row.PlatformType==1" alt="">
                  <span>订单：{{scope.row.TaoKeOrderNo}}</span>
                  <span v-if="scope.row.PayTime">支付时间：{{scope.row.PayTime}}</span>
                  <span v-if="scope.row.ReceiveTime">确认收货时间：{{scope.row.ReceiveTime}}</span>
                  <span v-if="scope.row.SettlementTime">结算时间：{{scope.row.SettlementTime}}</span>
                  <el-tag type="danger" effect="dark" size="mini" v-if="scope.row.PriceCompareStatus"
                   style="background:#FF7A45FF;line-height:20px;height:20px;margin-right:10px">比价订单</el-tag>
                </div>
                <div class="header-btn">
                  <el-button class="t" type='text' @click="CopyDetails(scope.row)">复制</el-button>
                </div>

              </div>
              <div class="product-name-box" v-else-if='scope.row.type===2'>
                <div class="product-name">
                  <span>{{scope.row.ProductName}}</span>
                </div>
                <div style="color:#909399;" class="product-name">
									{{scope.row.GoodsId}}
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="" label="小计（元）">
						<template slot-scope="scope">
							<div>
								￥{{scope.row.RealMoney}}
							</div>
						</template>
					</el-table-column>
          <el-table-column prop="" label="数量">
            <template slot-scope="scope">
              <span>{{scope.row.ProductCount}}件</span>
            </template>
          </el-table-column>
          <el-table-column prop="" label="订单金额（元）">
						<template slot-scope="scope">
							<div>
								￥{{scope.row.OrderMoney}}
							</div>
						</template>
					</el-table-column>
          <el-table-column prop="" label="收益比">
						<template slot-scope="scope">
							<div>
								{{scope.row.PromotionRateValue}}
							</div>
						</template>
					</el-table-column>
          <el-table-column prop="" label="收益（元）">
						<template slot-scope="scope">
							<div>
                <div class="color-999999">预估：￥{{scope.row.PredictPromotionMoney}}</div>
                <div class="color-606266">
                  结算：￥{{scope.row.SettlePromotionMoney==0&&[0,1,2].includes(scope.row.TaoKeOrderState)?'--':scope.row.SettlePromotionMoney}}
                </div>
							</div>
						</template>
					</el-table-column>
          <el-table-column prop="" label="分出收益（元）">
						<template slot-scope="scope">
							<div>
                <div class="color-999999">预估：￥{{scope.row.PredictDivideOutPromotionMoney}}</div>
                <div class="color-606266">
                  结算：￥{{scope.row.SettleDivideOutPromotionMoney==0&&[0,1,2].includes(scope.row.TaoKeOrderState)?'--':scope.row.SettleDivideOutPromotionMoney}}
                </div>
							</div>
						</template>
					</el-table-column>
          <el-table-column prop="" label="利润（元）">
						<template slot-scope="scope">
							<div>
                <div class="color-999999">预估：￥{{scope.row.PredictPromotionProfitMoney}}</div>
                <div class="color-606266">
                  结算：￥{{scope.row.SettlePromotionProfitMoney==0&&[0,1,2].includes(scope.row.TaoKeOrderState)?'--':scope.row.SettlePromotionProfitMoney}}
                </div>
							</div>
						</template>
					</el-table-column>
          <el-table-column label="下单人">
            <template slot-scope="scope">
              <div style="color:#409EFF;cursor: pointer" v-if="scope.row.CustomerWxNickname"
               @click="toBuyerMsg(scope.row)">
                {{scope.row.CustomerWxNickname}}
              </div>
              <div v-else>--</div>
            </template>
          </el-table-column>
          <el-table-column label="订单状态">
            <template slot-scope="scope">
              <div class="flex flex-align-center">
                <div :style="{'color':[4,6].includes(scope.row.TaoKeOrderState)?'#F56C6C':''}">{{scope.row.TaoKeOrderStateValue}}</div>
                <el-popover placement="top-start" trigger="hover" v-if="[4,6].includes(scope.row.TaoKeOrderState)">
                  <div v-if="scope.row.PromotionCheckFailReason" style="max-width:200px">
                    {{scope.row.PromotionCheckFailReason}}
                  </div>
                  <div v-else style="max-width:200px">
                    未成团、全部退款、违规被处罚、违规推广等订单收益不结算，详细失败原因可前往多多进宝后台查看
                  </div>
                  <span style="color: #F56C6C" slot="reference">
                    <i class="el-icon-warning-outline margin-left-5"></i>
                  </span>
                </el-popover>
              </div>
              
            </template>
          </el-table-column>
          <el-table-column label="店铺">
            <template slot-scope="scope">
              <div class="ellispis-two">
                {{scope.row.TaoKeStoreName}}
              </div>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="text" class="pointers" style="margin-right:5px;" @click="toEarnings(scope.row)">
                收益明细
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <div style="text-align: right;">
          <el-pagination v-if="Total" style="margin-top:10px" @size-change="handleSizeChange"
            @current-change="handleCurrentChange" :current-page="currentPage" :page-sizes="[10, 20, 30, 40, 50]"
            :page-size="pageSize" layout="total, sizes, prev, pager, next, jumper" :total="Total">
          </el-pagination>
        </div>
      </div>

    </el-card>
  </div>
</template>

<script>
	import {
		taoKeOrderlist,
	} from '@/api/sv3.0.0'


	import config from '@/config/index'
	import buttonPermissions from '@/components/buttonPermissions';
	import axios from 'axios'
	import Copy1 from 'clipboard';

	import eyesHide from "@/components/eyesHide"


  import {
    nextDay
  } from "@/utils"
	
	import {
		mapGetters
	} from 'vuex'
	export default {
		components: {
			buttonPermissions,
			eyesHide,
		},
		data() {
			return {
				uploadimgApi:config.UPLOAD_IMG,
				imgurl:config.IMG_BASE,
				screenHeight: document.documentElement.clientHeight,
				screenWidth: document.documentElement.clientWidth,
				imgUrl: config.IMG_BASE,
				
				baseAPI: config.BASE_URL,
				
				searchKey: '',
				timeType: 0,
				timeTypeList: [{
						id: 0,
						value: '订单支付时间'
					},
					{
						id: 1,
						value: '订单确认收货时间'
					},
					{
						id: 2,
						value: '订单结算时间'
					},
				],
        searchTime:[],
				orderStage: null,
				orderStageList: [
					{value:null,label:'全部'},
					{value:0,label:'已支付'},
					{value:1,label:'已成团'},
					{value:2,label:'确认收货'},
					{value:3,label:'审核成功'},
					{value:4,label:'审核失败'},
					{value:5,label:'已结算'},
					{value:6,label:'已处罚'},
				],
				groupData:[],
				loading: false,
				currentPage: 1,
				pageSize: 20,
				Total: 0,
			}
		},
		beforeMount() {
			this.getList()
		},
		mounted() {
			const that = this
			window.onresize = () => {
				return (() => {
					that.screenHeight = document.documentElement.clientHeight
					that.screenWidth = document.documentElement.clientWidth
				})()
			}
			that.clipboard = (text) => {
				const cb = new Copy1('.t', {
					text: () => {
						return text.TaoKeOrderNo
					}
				})
				cb.on('success', (e) => {
					that.$message({
						message: '订单编号复制成功',
						type: 'success'
					});
					cb.destroy()
					e.clearSelection()
				})
				cb.on('error', (e) => {})
			}
		},
		watch: {
			screenHeight(val) {
				if (!this.timer) {
					this.screenHeight = val
					this.timer = true
					let that = this
					setTimeout(function() {
						that.timer = false
					}, 400)
				}
			},
			screenWidth(val) {
				if (!this.timerW) {
					this.screenWidth = val
					this.timerW = true
					setTimeout(() => {
						this.timerW = false
					}, 400)
				}
			}
		},
		computed: {

			...mapGetters([
				'mallInfo',
			]),
		},
		methods: {
      toEarnings(row){
        this.$router.push({
					name:'taokeEarnings',
					params:{
						Id:row.TaoKeOrderNo
					}
				})
      },
			toBuyerMsg(row){
				this.$router.push({
					path:'/Customer/CustomerDetail',
					query:{
						Id:row.CustomerId
					}
				})
			},
			CopyDetails(data) {
				this.clipboard(data)
			},
			headerStyle() {
				return {
					background: '#f0f2f5'
				}
			},
			setClassName({
				row,
				index
			}) {
				if (row.type == 1) {
					return 'special-row'
				}else if(row.type == 3){
					return 'noDistance-row'
				}
			},
			arraySpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (row.type === 1 || row.type === 3) {
					if (columnIndex === 0) {
						return {
							rowspan: 1,
							colspan:12
						};
					} else {
						return {
							rowspan: 0,
							colspan: 0
						};
					}
				} else {
					if (columnIndex === 5 || columnIndex === 6 || columnIndex === 7 || columnIndex === 8 || columnIndex ===
						9 || columnIndex === 10 || columnIndex === 11) {
						if (row.length) {
							return {
								rowspan: row.length,
								colspan: 1
							};
						} else {
							return {
								rowspan: 0,
								colspan: 0
							};
						}
					}
				}
			},

			handleInput2(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,0})/g)[0]) || null

			},
			handleInput3(e) {
				// 通过正则过滤小数点后两位
				e.target.value = (e.target.value.match(/^\d*(\.?\d{0,1})/g)[0]) || null
			},
			objectSpanMethod({
				row,
				column,
				rowIndex,
				columnIndex
			}) {
				if (columnIndex === 5 || columnIndex === 6 || columnIndex === 7) {
					return {
						rowspan: 1,
						colspan: 1
					};
				}

			},
			// 设置关键字
			rowKeys(row) {
				return row.TaoKeOrderNo
			},

			// 获取列表
			async getList() {
				try {
					this.loading = true
					let data = {
						Keywords: this.searchKey, 
            TaoKeOrderState:this.orderStage,
            TimeType:this.timeType,
						Skip: (this.currentPage - 1) * this.pageSize, 
						Take: this.pageSize, 
					}
          if(this.searchTime&&this.searchTime.length){
            data.StartTime = this.searchTime[0]
            data.EndTime = nextDay(this.searchTime[1],1)
          }
					let result = await taoKeOrderlist(data);
					this.Total = result.Result.Total;
					
					this.dealData(result)

				} catch (e) {
					console.log(e)
				} finally {
					this.loading = false
				}

			},

			dealData(result) {
				let list = result.Result.Results || [];
				this.groupData = []
				// console.log(list)
				list.forEach((t) => {
					// console.log(t)
					let item = {
						type: 1,
						TaoKeOrderNo: t.TaoKeOrderNo,
						PayTime: t.PayTime,
						ReceiveTime:t.ReceiveTime,
            PriceCompareStatus:t.PriceCompareStatus,
            SettlementTime:t.SettlementTime,
            PlatformType:t.PlatformType,
            PlatformTypeValue:t.PlatformTypeValue
					}
					// console.log(item,123)
					this.groupData.push(item)
					t.OrderDetailList.forEach((tt, ii) => {
						let iitem = {}
						if (ii === 0) {
							iitem = {
								...tt,
								type: 2,
								TaoKeOrderNo: t.TaoKeOrderNo,
                PlatformTypeValue:t.PlatformTypeValue,

								...t,
								length: t.OrderDetailList.length,
                OrderDetailList:undefined
							}
						} else {
							iitem = {
								...tt,
								type: 2,
								TaoKeOrderNo: t.TaoKeOrderNo,
								WaybillId:tt.WaybillId,
							}
						}
						this.groupData.push(iitem)
					})
				})

				this.groupData.map(item => {
					item.startVisible = false
					if(this.viewOrder==4){
						if(item.TaoKeOrderNo==this.showTaoKeOrderNo){
							item.GroupLeader = true
						}
					}
					if(this.viewOrder==6){
						// console.log(item.MemberHelpProInitiateTaoKeOrderNo,item.TaoKeOrderNo)
						if(item.TaoKeOrderNo==this.showTaoKeOrderNo){
							item.IsShowInitiateOrderTag = true
						}
					}
					return item
				})
				// console.log('this.groupData', this.groupData)
			},

			goodsFilter() {
				this.currentPage = 1;
				this.getList()
			},


			// 切换显示条数
			handleSizeChange(val) {
				this.pageSize = val;
				this.getList();
			},

			// 翻页
			handleCurrentChange(val) {
				this.currentPage = val;
				this.getList();
			},

			// 导出
			exportFun() {
        let url = location.origin + '/pc/taoKeOrder/export?'
        url += 'Keywords=' + encodeURI(this.searchKey)
        url += '&TaoKeOrderState=' + (this.orderStage==null?'':this.orderStage)
        url += '&TimeType=' + this.timeType
        if(this.searchTime&&this.searchTime.length){
          url += '&StartTime=' + this.searchTime[0]
          url += '&EndTime=' + nextDay(this.searchTime[1],1)
        }
        window.open(url)
			},


		}
	}
</script>

<style lang="less" scoped>
.orderShowMsg{
	display: flex;
	align-items: center;
	justify-content: space-between;
	background: #F8F8F9;
	border: 1px solid #EBEEF5;
	padding: 10px;
	margin-left:45px;
	font-size: 14px;
	color:#606266FF;
	.blue-column{
		width: 3px;
		height: 22px;
		background: #409EFF;
	}
}
.sel-noborder{
	::v-deep input{
		border:none
	}
}.el-image-whiteclose{
	::v-deep .el-icon-circle-close{
		color:white
	}
}
	.img-remark{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		.remark-re{
			position: relative;
			top: 0;
			left: 0;
			width:60px;height:60px;
			border-radius:3px;
			// border:1px solid black;
			margin: 0px 10px 10px 0px;
			.remark-close{
				width:14px;height:14px;
				border-radius: 50%;
				position: absolute;
				top: -7px;
				left: calc(100% - 7px);
				display: flex;
				align-items: center;
				justify-content: center;
				cursor: pointer;
				z-index: 500;
			}
		}
		.upload-box{
			::v-deep .el-upload--picture-card{
				width: 60px;height:60px;
				line-height:60px;
				display: flex;
				align-items: center;
				justify-content: center;
			}
		}
	}
	.flexRow{
		display: flex;
		align-items: center;
	}
	.disableButton{
		width: 120px;
		margin-left: 10px;
		height: 36px;
		border-radius: 2px 2px 2px 2px;
		opacity: 1;
		border: 1px solid #E9E9EB;
		font-size: 14px;
		color: #909399;
		line-height: 1.5;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #E9E9EB;
	}
	.table-container {
		::v-deep .el-table--enable-row-hover .el-table__body tr:hover>td {
			background-color: rgba(0, 0, 0, 0) !important;
		}

		.header-box {
			display: flex;
			padding: 0 10px;
			flex-direction: row;
			justify-content: space-between;
			align-content: center;

			.header-info {
				span {
					display: inline-block;
					line-height: 40px;
					margin-right: 20px;
				}
			}

			.header-btn {
				min-width: 120px;
			}
		}

		.remark-box {
			// border:1px solid black;
			.remark-distribution{
				background: rgba(245, 244, 247,1);
				color: #f56c6c;
				padding: 10px;
				word-wrap: break-word;
				width: 100%;
			}
			.customer-remark {
				background: rgba(217, 236, 255, 1);
				color: rgba(64, 158, 255, 1);
				padding: 10px;
				word-wrap: break-word;
				width: 100%;
			}
			.merchant-remark{
				background: #FDF6EC;
				color: #FDA23CFF;
				padding: 10px;
				word-wrap: break-word;
				width: 100%;
			}

			.system-remark {
				background: rgba(253, 246, 236, 1);
				color: rgba(253, 162, 60, 1);
				padding: 10px;
				word-wrap: break-word;
				width: 100%;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				font-size: 14px;
			}
		}

		.product-name-box {
			.product-name {
				width: 200px;
				overflow: hidden;
				text-overflow: ellipsis;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
			}
		}

		::v-deep .special-row {
			padding-top: 10px;

			td {
				padding: 10px 0 0 0 !important;
			}

			.cell {
				padding: 0 !important;
			}

			.name-wraper {
				padding: 5px 10px;

				.line span {
					line-height: 30px;
					display: inline-block;
				}
			}
		}
		::v-deep .noDistance-row{
			td {
				padding: 0 !important;
			}

			.cell {
				padding: 0 !important;
			}
		}
	}

	.ordermange {
		width: 100%;
		background: #fff;
		padding: 10px;
		// padding: 30px;

		.nocanDo {
			.el-dialog__body {
				padding: 10px 20px 20px 20px !important;
			}
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}


		.classBottoms {
			margin-bottom: 10px;
		}

		.label {
			text-align: right;
			display: inline-block;
			width: 130px;
		}

		.pointers {
			cursor: pointer;
		}

		.sendClass {
			color: #333;
			font-size: 14px;
			padding: 0 5px;
			line-height: 25px;
		}

		.prograss-box {
			position: fixed;
			right: 0;
			bottom: 60px;
			z-index: 100;
			background: #DCDFE6;
		}

		.el-alert {
			border-radius: 0;
		}

		.bath-checkorders {
			.el-dialog__header {
				padding: 0px 20px 15px !important;
			}
		}

		input[type=number]::-webkit-inner-spin-button {
			-webkit-appearance: none;
		}

		.number__input {
			border: 1px solid #ccc;
		}

		.editMoneyNotice {
			background: rgba(253, 246, 236, 1);
			color: rgba(253, 162, 60, 1);
			padding: 10px;
			word-wrap: break-word;
			width: 95%;
			margin-top: 10px;
		}
	}

	.el-alert {
		.el-alert__content {
			width: 100%;
		}

		.el-alert__title {
			word-wrap: break-word;
		}
	}

	.send-dialog {
		.top {
			margin-bottom: 10px;

			.wait-d {
				color: #f56c6c;
				margin-right: 10px;
			}

			.deliveried {
				color: #67c23a;
			}
		}

		.name-content {
			margin: 10px 0;
			display: flex;
			flex-direction: row;
			align-items: center;

			img {
				width: 60px;
				height: 60px;
				object-fit: contain;
				margin-right: 10px;
			}

			.product-name {
				width: 200px;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}
	}
</style>
